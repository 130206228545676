export const languages = [
  {
    code: 'en',
    name: 'EN'
  },
  {
    code: 'zh-tw',
    name: '繁中'
  },
  {
    code: 'zh',
    name: '简中'
  }
]
