import { useI18next } from 'gatsby-plugin-react-i18next'
import { languages } from 'components/langSwitcher/languages.static'

export const setImagePostfix = (imageSrc) => {
  if (imageSrc.indexOf('.') > -1) {
    const { defaultLanguage, language } = useI18next()
    const splittedImageSrc = imageSrc.split('.')
    const fileType = splittedImageSrc.pop()
    const imagePostfix = language === defaultLanguage ? '' : `_${language}`

    return splittedImageSrc.join('.') + imagePostfix + '.' + fileType
  }
  return imageSrc
}
export const getLocaleName = (code) => {
  let currentName = ''

  for (const language of languages) {
    if (language.code === code) {
      currentName = language.name
      break
    }
  }
  return currentName
}
